<template>
  <div id="app" class="noSelect">

    <div id="memoryState" class="progress" style="border-radius: 0" :class="{'filter-blur': isModalShow}">
      <div class="progress-bar border border-dark" role="progressbar"
           data-bs-toggle="tooltip" data-bs-html="true"
           :title="'进程号: ' + block[0] + '\t起始地址: ' + block[1] + '\t长度: ' + block[2] +
                  '\t分配情况: ' + MemoryBlockStateName[block[3]]"
           v-for="(block, index) in memoryList" :key="index"
           :class="memoryColorList[block[3]]"
           :style="'width:' + block[2] * 100 / Config.Memory + '%'"></div>
    </div>

    <div class="card main-card" :class="{'filter-blur': isModalShow}">
      <h5 class="card-header">操作系统原理实验：模拟处理机调度与内存分配</h5>
      <div v-if="!isPageChanged" class="card-body main-card-body main-card-body-one">

        <div class="sub-card-container">
          <div class="card sub-card create-card box-shadow">
            <h5 class="card-header">创建进程</h5>
            <div class="card-body sub-card-body create-card-body">

              <div class="third-card-container">
                <div class="card third-card pcb-info-card">
                  <h5 class="card-header">进程信息</h5>
                  <div class="card-body third-card-body pcb-info-card-body row g-3 box">
                    <div class="input-group">
                      <span class="input-group-text">进&nbsp;程&nbsp;号&nbsp;</span>
                      <input class="form-control" type="text" :value=this.nextPID Disabled readonly>
                    </div>

                    <div class="input-group">
                      <span class="input-group-text">所需时间</span>
                      <input class="form-control" type="text" v-model="time"
                             autocomplete="off" placeholder="请输入正整数">
                    </div>

                    <div class="input-group">
                      <span class="input-group-text">所需内存</span>
                      <input class="form-control" type="text" v-model="memory"
                             autocomplete="off" placeholder="请输入正整数">
                    </div>

                    <div class="input-group">
                      <span class="input-group-text">优&nbsp;先&nbsp;级&nbsp;</span>
                      <select class="form-select" v-model="priority">
                        <option value="1" selected>1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                      </select>
                    </div>

                  </div>
                </div>

                <div class="card third-card pcb-precursor-card">
                  <h5 class="card-header">前驱进程</h5>
                  <div class="card-body third-card-body pcb-precursor-card-body text-indent">

                    <div class="form-check" v-for="(pcb, index) in pcbList" :key="index">
                      <input class="form-check-input" type="checkbox"
                             :value="pcb.getPID()" :id="'precursor-' + pcb.getPID()" v-model="precursorList">
                      <label class="form-check-label" :for="'precursor-' + pcb.getPID()"
                             data-bs-toggle="tooltip" data-bs-html="true"
                             :title="'状态: ' + PCBStateName[pcb.getState()] + '\t时间: ' + pcb.getTime() +
                                      '\t内存: ' + pcb.getMemory() + '\t优先级: ' + pcb.getPriority() +
                                      '\t属性: ' + PropertyName[pcb.getProperty()] +
                                      '\n前驱: ' + pcb.getPrecursorList() + '\n后继: ' + pcb.getSuccessorList()">
                        进程 {{ pcb.getPID() }}
                      </label>
                    </div>

                  </div>
                </div>
              </div>

              <div class="btn-container">
                <button class="btn btn-outline-success" style="margin: 21px"
                        @click="createProcessFromInput">创建进程
                </button>
                <button class="btn btn-outline-secondary" style="margin: 21px"
                        @click="createProcessRandomly">随机创建十个进程
                </button>
              </div>

            </div>
          </div>

          <div class="card sub-card backup-card box-shadow">
            <h5 class="card-header">后备队列</h5>
            <div class="card-body sub-card-body backup-card-body">
              <div class="card check-container backup-check-container card-body text-indent">

                <div class="form-check" v-for="pid in backupList" :key="'backup-' + pid">
                  <input class="form-check-input" type="checkbox"
                         :value="pid" :id="'backup-' + pid" v-model="backupListToRemove">
                  <label class="form-check-label" :for="'backup-' + pid"
                         data-bs-toggle="tooltip" data-bs-html="true"
                         :title="'\t时间: ' + pcbQueue.getPCBByPID(pid).getTime() +
                                  '\t内存: ' + pcbQueue.getPCBByPID(pid).getMemory() +
                                  '\t优先级: ' + pcbQueue.getPCBByPID(pid).getPriority() +
                                  '\t属性: ' + PropertyName[pcbQueue.getPCBByPID(pid).getProperty()] +
                                  '\n前驱: ' + pcbQueue.getPCBByPID(pid).getPrecursorList() +
                                  '\n后继: ' + pcbQueue.getPCBByPID(pid).getSuccessorList()">
                    进程 {{ pid }}
                  </label>
                </div>

              </div>

            </div>
          </div>

          <div class="card sub-card hanging-card box-shadow">
            <h5 class="card-header">挂起队列</h5>
            <div class="card-body sub-card-body hanging-card-body">
              <div class="card check-container hanging-check-container card-body text-indent">

                <div class="form-check" v-for="pid in hangingList" :key="'backup-' + pid">
                  <input class="form-check-input" type="checkbox"
                         :value="pid" :id="'hanging-' + pid" v-model="hangingListToRemove">
                  <label class="form-check-label" :for="'hanging-' + pid"
                         data-bs-toggle="tooltip" data-bs-html="true"
                         :title="'\t时间: ' + pcbQueue.getPCBByPID(pid).getTime() +
                                  '\t内存: ' + pcbQueue.getPCBByPID(pid).getMemory() +
                                  '\t优先级: ' + pcbQueue.getPCBByPID(pid).getPriority() +
                                  '\t属性: ' + PropertyName[pcbQueue.getPCBByPID(pid).getProperty()] +
                                  '\n前驱: ' + pcbQueue.getPCBByPID(pid).getPrecursorList() +
                                  '\n后继: ' + pcbQueue.getPCBByPID(pid).getSuccessorList()">
                    进程 {{ pid }}
                  </label>
                </div>

              </div>

              <div class="btn-container">
                <button class="btn btn-outline-info" @click="removeFromHanging">解挂进程</button>
              </div>

            </div>
          </div>

          <div class="card sub-card processor-card box-shadow">
            <h5 class="card-header">就绪队列</h5>
            <div class="card-body sub-card-body processor-card-body"
                 :style="'width: calc(var(--listWidth) * ' + Config.ProcessorNum + ')' ">
              <div class="third-card-container">

                <div class="card third-card" :style="'width: ' + 100 / Config.ProcessorNum + '%'"
                     v-for="(processor, index) in processorList" :key="'processor-' + index">
                  <h5 class="card-header">处理机 {{ index + 1 }}</h5>

                  <div class="card-body third-card-body text-indent">
                    <div class="form-check" v-for="pid in processor" :key="'processor-' + pid">
                      <input class="form-check-input" type="checkbox"
                             :value="pid" :id="'processor-' + pid" v-model="processorListToRemove">
                      <label class="form-check-label" :for="'processor-' + pid"
                             data-bs-toggle="tooltip" data-bs-html="true"
                             :title="'\t时间: ' + pcbQueue.getPCBByPID(pid).getTime() +
                                  '\t内存: ' + pcbQueue.getPCBByPID(pid).getMemory() +
                                  '\t优先级: ' + pcbQueue.getPCBByPID(pid).getPriority() +
                                  '\t属性: ' + PropertyName[pcbQueue.getPCBByPID(pid).getProperty()] +
                                  '\n前驱: ' + pcbQueue.getPCBByPID(pid).getPrecursorList() +
                                  '\n后继: ' + pcbQueue.getPCBByPID(pid).getSuccessorList()">
                        进程 {{ pid }}
                      </label>
                    </div>

                  </div>
                </div>

              </div>

              <div class="btn-container">
                <button class="btn btn-outline-warning" @click="fromProcessorToHanging">挂起进程</button>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div v-if="isPageChanged" class="card-body main-card-body main-card-body-two">
        <div class="sub-card-container">
          <div class="card sub-card memory-card box-shadow">
            <div class="card-header">内存空间</div>
            <div class="card-body no-padding">
              <table class="table table-striped table-hover">
                <thead>
                <tr>
                  <th scope="col">进程号</th>
                  <th scope="col">起始地址</th>
                  <th scope="col">长度</th>
                  <th scope="col">分配情况</th>
                </tr>
                </thead>
                <tbody>

                <tr v-for="(block, index) in memoryList" :key="index">
                  <td>{{ block[0] }}</td>
                  <td>{{ block[1] }}</td>
                  <td>{{ block[2] }}</td>
                  <td>{{ MemoryBlockStateName[block[3]] }}</td>
                </tr>

                </tbody>
              </table>
            </div>
          </div>

          <div class="card sub-card pcb-card box-shadow">
            <div class="card-header">PCB 状态信息</div>
            <div class="card-body no-padding">
              <table class="table table-striped table-hover">
                <thead>
                <tr>
                  <th scope="col">进程号</th>
                  <th scope="col">状态</th>
                  <th scope="col">时间</th>
                  <th scope="col">内存</th>
                  <th scope="col">优先级</th>
                  <th scope="col">属性</th>
                  <th scope="col">前驱</th>
                  <th scope="col">后继</th>
                </tr>
                </thead>
                <tbody>

                <tr v-for="(pcb, index) in pcbList" :key="index">
                  <td>{{ pcb.getPID() }}</td>
                  <td>{{ PCBStateName[pcb.getState()] }}</td>
                  <td>{{ pcb.getTime() }}</td>
                  <td>{{ pcb.getMemory() }}</td>
                  <td>{{ pcb.getPriority() }}</td>
                  <td>{{ PropertyName[pcb.getProperty()] }}</td>
                  <td>{{ pcb.getPrecursorList() }}</td>
                  <td>{{ pcb.getSuccessorList() }}</td>
                </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="dock" :class="{'filter-blur': isModalShow}">
      <BaseButton content="修改配置" @click.native="isModalShow = true"
                  style="background:linear-gradient(135deg,#485563,#29323c)"></BaseButton>
      <BaseButton content="切换视图" @click.native="isPageChanged = !isPageChanged"
                  style="background:linear-gradient(135deg,#fc354c,#0abfbc)"></BaseButton>
      <BaseButton content="运行一步" @click.native="oneStep"
                  style="background:linear-gradient(135deg,#fce38a,#f38181)"></BaseButton>
      <BaseButton content="运行十步" @click.native="tenSteps"
                  style="background:linear-gradient(135deg,#abdcff,#0396ff)"></BaseButton>
      <div id="speed">
        <label for="speedRange" class="form-label">运行速度</label>
        <span style="float: left">慢</span>
        <span style="float: right">快</span>
        <input type="range" class="form-range" min="1" max="5" step="1" id="speedRange"
               v-model="speed" @change="speedChanged">
      </div>
      <BaseButton :content="btn_running_content[flag_running]" @click.native="autoSteps"
                  :style="'background:' + btn_running_color[flag_running]"></BaseButton>

    </div>

    <transition name="bounce" mode="out-in" appear>
      <div v-if="isModalShow" id="modifyConfig">
        <div class="card config-info-card box-shadow">
          <h5 class="card-header">修改配置信息</h5>
          <div class="card-body third-card-body config-info-card-body">
            <div class="input-group-container" style="padding: 1rem">

              <div class="input-group mb-4">
                <span class="input-group-text">处理机个数</span>
                <input class="form-control" type="text" v-model="NewConfig.ProcessorNum"
                       autocomplete="off">
              </div>

              <div class="input-group mb-4">
                <span class="input-group-text">处理机道数</span>
                <input class="form-control" type="text" v-model="NewConfig.ProcessorSeatNum"
                       autocomplete="off">
              </div>

              <div class="input-group mb-4">
                <span class="input-group-text">总内存大小</span>
                <input class="form-control" type="text" v-model="NewConfig.Memory"
                       autocomplete="off">
              </div>

              <div class="input-group mb-4">
                <span class="input-group-text">系统保留区</span>
                <input class="form-control" type="text" v-model="NewConfig.OSMemory"
                       autocomplete="off">
              </div>

            </div>

            <div class="btn-container">
              <BaseButton content="确认" class="bg-primary" @click.native="confirmModification"></BaseButton>
              <BaseButton content="取消" class="bg-secondary"
                          @click.native="cancelModification"></BaseButton>
            </div>

          </div>
        </div>
      </div>
    </transition>

    <div class="toast-container position-fixed top-0 end-0 p-3">
      <div id="liveToast" class="toast align-items-center bg-warning border-0"
           role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="2100">
        <div class="d-flex">
          <div class="toast-body">
            {{ warningText }}
          </div>
          <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton";

export default {
  name: 'App',
  components: {BaseButton},
  data() {
    return {
      warningText: '我们都有美好的未来！',
      isPageChanged: false,
      Config: {ProcessorNum: 3, ProcessorSeatNum: 7, Memory: 256, OSMemory: 64},
      NewConfig: {ProcessorNum: 3, ProcessorSeatNum: 7, Memory: 256, OSMemory: 64},
      nextPID: 0,
      time: '',
      memory: '',
      priority: 1,
      pidList: [],
      pcbList: [],
      precursorList: [],
      backupList: [],
      backupListToRemove: [],
      hangingList: [],
      hangingListToRemove: [],
      processorList: [[], []],
      processorListToRemove: [],
      pcbQueue: new PCBQueue(),
      backupQueue: new BackupQueue(),
      hangingQueue: new HangingQueue(),
      mainMemory: new MainMemory({ProcessorNum: 3, ProcessorSeatNum: 7, Memory: 256, OSMemory: 64}),
      memoryList: [],
      speed: 3,
      flag_running: false,
      runInterval: null,
      btn_running_color: {
        true: 'linear-gradient(135deg,#e53935,#e35d5b)',
        false: 'linear-gradient(135deg,#76b852,#8dc26f)'
      },
      btn_running_content: {true: '停止运行', false: '自动运行'},
      isModalShow: false,
      memoryColorList: {0: 'bg-success', 1: 'bg-warning', 2: 'bg-primary'},
      MemoryBlockStateName: {0: "未分配", 1: "已分配", 2: "系统保留"},
      PropertyName: {0: "独立进程", 1: "同步进程"},
      PCBStateName: {
        0: "进程创建",
        1: "活动就绪",
        2: "静止就绪",
        3: "进程运行",
        4: "进程挂起",
        5: "进程结束"
      },
      processor: new Processor({ProcessorNum: 3, ProcessorSeatNum: 7, Memory: 256, OSMemory: 64})
    }
  },
  methods: {
    createProcessFromInput() {
      this.time = this.time.replace(/\s+/, '');
      this.memory = this.memory.replace(/\s+/, '')

      if (!this.time.match('^[1-9][0-9]*$')) {
        console.log('创建失败: 时间必须是一个正整数！')
        this.warningText = '创建失败: 时间必须是一个正整数！'
        const myToastLive = document.getElementById('liveToast')
        const myToast = new bootstrap.Toast(myToastLive)
        myToast.show()
        return
      }
      if (!this.memory.match('^[1-9][0-9]*$')) {
        console.log('创建失败: 内存必须是一个正整数！')
        this.warningText = '创建失败: 内存必须是一个正整数！'
        const myToastLive = document.getElementById('liveToast')
        const myToast = new bootstrap.Toast(myToastLive)
        myToast.show()
        return
      }

      let pid = this.nextPID
      let time = parseInt(this.time);
      let memory = parseInt(this.memory);
      let priority = this.priority
      let property = this.precursorList.length === 0 ? Property.INDEPENDENT : Property.SYNCHRONIZED;
      let precursorList = this.precursorList

      this.createProcess(pid, time, memory, priority, property, precursorList)
    },
    createProcessRandomly() {
      for (let i = 0; i < 10; i++) {
        let pid = this.nextPID
        let time = Math.floor(Math.random() * 20) + 1;
        let memory = Math.floor(Math.random() * 32) + 1;
        let priority = Math.floor(Math.random() * 9) + 1;
        let property = Math.floor(Math.random());
        let precursorList = []
        let precursorNum = Math.floor(Math.random() * this.pidList.length + 1)

        if (this.pidList.length > 0) {
          for (let j = 0; j < precursorNum; j++) {
            let index = Math.floor(Math.random() * this.pidList.length);
            let precursor = this.pidList[index]
            let pcb = this.pcbQueue.getPCBByPID(precursor)
            if (pcb.getState() !== PCBState.EXIT && !precursorList.includes(precursor)) {
              precursorList.push(precursor)
            }
          }
        }
        this.createProcess(pid, time, memory, priority, property, precursorList)
      }
    },
    createProcess(pid, time, memory, priority, property, precursorList) {
      let new_pcb = new PCB(pid, time, memory, priority, property, precursorList)
      this.pcbQueue.appendPCB(new_pcb)
      this.backupQueue.appendPCB(this.pcbQueue, new_pcb)
      this.backupList = this.backupQueue.getBackupList()
      this.processorList = this.processor.getProcessorPCBList()
      this.nextPID = this.pcbQueue.getNextPid()
      this.pidList = this.pcbQueue.getPIDList()
      this.pcbList = this.pcbQueue.getPCBList()

      // this.addTooltips()
    },
    fromProcessorToHanging() {
      for (let pid of this.processorListToRemove) {
        let pcb = this.pcbQueue.getPCBByPID(pid)
        this.processor.removePCB(pcb)
        this.mainMemory.removePCB(pcb, this.processor)
        this.hangingQueue.appendPCB(pcb)
        this.hangingList = this.hangingQueue.getHangingList()
        this.processorList = this.processor.getProcessorPCBList()
        this.processorListToRemove = []

        // this.addTooltips()
      }
    },
    removeFromHanging() {
      for (let pid of this.hangingListToRemove) {
        let pcb = this.pcbQueue.getPCBByPID(pid)
        let [isAssignable, partitionNum] = this.mainMemory.checkAssignable(pcb, this.processor)
        if (isAssignable) {
          this.hangingQueue.removePCB(pcb)
          this.mainMemory.insertPCB(this.pcbQueue, pcb, partitionNum)
          this.processor.dispatchPCB(this.mainMemory.sortedList)
          this.processorList = this.processor.getProcessorPCBList()
          this.hangingList = this.hangingQueue.hangingList
        } else {
          console.log('解挂失败: 内存不足！')
          this.warningText = '解挂失败: 内存不足！'
          const myToastLive = document.getElementById('liveToast')
          const myToast = new bootstrap.Toast(myToastLive)
          myToast.show()
        }
        this.hangingListToRemove = []
        // this.addTooltips()
      }
    },
    oneProcess() {
      let isProcessable = this.processor.checkProcessable(this.pcbQueue)
      if (isProcessable) {
        this.processor.process(this.pcbQueue, this.mainMemory, this.backupQueue)
        this.processorList = this.processor.getProcessorPCBList()
        this.backupList = this.backupQueue.getBackupList()
        this.pcbList = this.pcbQueue.getPCBList()
        // this.addTooltips()
      }
    },
    oneStep() {
      this.backupQueue.autoMoveToProcessor(this.pcbQueue, this.mainMemory, this.processor)
      this.oneProcess()
    },
    tenSteps() {
      this.backupQueue.autoMoveToProcessor(this.pcbQueue, this.mainMemory, this.processor)
      let steps = 10
      while (this.processorList.count() > 0 && steps > 0) {
        steps = steps - 1
        this.oneProcess()
      }
    },
    speedChanged() {
      let _this = this
      if (this.flag_running) {
        window.clearInterval(this.runInterval);
        this.runInterval = setInterval(function () {
          _this.oneProcess()
        }, Math.pow(2, (2 - this.speed)) * 1000);
      }
    },
    autoSteps() {
      this.backupQueue.autoMoveToProcessor(this.pcbQueue, this.mainMemory, this.processor)
      let _this = this
      if (this.flag_running) {
        window.clearInterval(this.runInterval);
      } else {
        this.runInterval = setInterval(function () {
          _this.oneProcess()
        }, Math.pow(2, (2 - this.speed)) * 1000);
      }
      this.flag_running = !this.flag_running
    },
    confirmModification() {
      const ConfigName = {
        ProcessorNum: '处理机个数',
        ProcessorSeatNum: '处理机道数',
        Memory: '总内存大小',
        OSMemory: '系统保留区大小'
      }
      for (let key in this.NewConfig) {
        if (this.NewConfig[key] <= 0) {
          console.log('配置修改失败: ' + ConfigName[key] + '应为正整数！')
          this.warningText = '配置修改失败: ' + ConfigName[key] + ' 应为正整数！'
          const myToastLive = document.getElementById('liveToast')
          const myToast = new bootstrap.Toast(myToastLive)
          myToast.show()
          return;
        }
      }
      if (this.NewConfig["Memory"] <= this.NewConfig["OSMemory"]) {
        console.log("系统保留区应小于总内存大小！")
        this.warningText = "系统保留区应小于总内存大小！"
        const myToastLive = document.getElementById('liveToast')
        const myToast = new bootstrap.Toast(myToastLive)
        myToast.show()
        return;
      }
      for (let key in this.Config) {
        this.Config[key] = this.NewConfig[key]
      }
      this.pcbQueue = new PCBQueue()
      this.backupQueue = new BackupQueue()
      this.hangingQueue = new HangingQueue()
      this.mainMemory = new MainMemory(this.NewConfig)
      this.processor = new Processor(this.NewConfig)
      this.pcbList = []
      this.precursorList = []
      this.backupList = []
      this.hangingList = []
      this.processorList = this.processor.processorPCBList
      this.memoryList = this.mainMemory.memoryList
      this.isModalShow = false
    },
    cancelModification() {
      this.isModalShow = false
      for (let key in this.Config) {
        this.NewConfig[key] = this.Config[key]
      }
    },
    addTooltips() {
      setTimeout(function () {
        let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        tooltipTriggerList.map(function (tooltipTriggerEl) {
          return new bootstrap.Tooltip(tooltipTriggerEl)
        })
      }, 21)
    }
  },
  mounted() {
    this.memoryList = this.mainMemory.memoryList
    this.processorList = this.processor.getProcessorPCBList()
    // this.addTooltips()
  }
};
</script>

<style>
:root {
  --listWidth: 9rem;
}

#app {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-rows: 1rem calc(100vh - 6rem) 5rem;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.card, .card-body {
  overflow-y: auto;
  overflow-x: hidden;
}

.main-card {
  height: 100%;
  width: 100vw;
  border: 0;
  border-radius: 0;
}

.filter-blur {
  filter: blur(7px);
}

.main-card-body {
  height: 100%;
  display: flex;
  align-items: center;
  background-image: linear-gradient(transparent 98%, #BDBDBD 98%),
  linear-gradient(to right, transparent 98%, #BDBDBD 98%);
  background-size: 2rem 2rem;
  background-repeat: repeat;
}

.sub-card-container {
  height: 90%;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.sub-card {
  height: 100%;
}

.sub-card-body {
  height: 100%;
  padding: 0;
  display: grid;
  grid-template-rows: 70% 30%;
}

.backup-card-body {
  grid-template-rows: 100%;
}

.create-card {
  width: calc(var(--listWidth) + 16rem);
}

.create-card-body .third-card-container {
  display: grid;
  grid-template-columns: 16rem var(--listWidth);
}

.backup-card, .hanging-card {
  width: var(--listWidth);
}

.processor-card {
  max-width: calc(var(--listWidth) * 3);
}

.processor-card-body {
  max-width: calc(var(--listWidth) * 3);
}

.processor-card .third-card-container {
  max-width: calc(var(--listWidth) * 3);
  display: flex;
  overflow: auto;
}

.processor-card .third-card-container .third-card {
  height: 100%;
  min-width: var(--listWidth)
}

.memory-card {
  width: calc(var(--listWidth) * 3)
}

.pcb-card {
  width: calc(var(--listWidth) * 6);
}

#modifyConfig {
  width: 100vw;
  height: 100vh;
  position: fixed;
}

.config-info-card {
  width: 21rem;
  height: 28rem;
  margin: calc((100vh - 28rem) / 2) calc((100vw - 21rem) / 2);
  border-radius: 1rem;
}

.config-info-card-body {
  display: grid;
  grid-template-rows: 70% 30%;
}

.btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-padding {
  padding: 0;
}

.text-indent {
  text-align: left;
  text-indent: 0.3rem;
}

#dock {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(-145deg, #ffffff, #e6e6e6);
}

#speed {
  width: 11rem;
  margin: 1rem 1rem auto 1em;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

</style>
