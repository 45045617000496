<template>
  <span class="btn" tabindex="0">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "BaseButton"
}
</script>

<style scoped>
.btn {
  font-weight: 700;
  font-size: 1rem;
  border: none;
  outline: none;
  min-width: 6rem;
  text-align: center;
  box-sizing: border-box;
  text-decoration: none;
  color: #FFFFFF;
  letter-spacing: 2px;
  width: auto;
  padding: 11px;
  border: 0;
  border-radius: 7px;
  margin: 21px;
  box-shadow: 0 1px 1px rgba(255, 255, 255, 0.8) inset,
  1px 1px 3px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease-in-out;
  background: #4D79C2;
}

.btn:active {
  position: relative;
  top: 2px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4) inset;
}

.btn:before {
  content: attr(content);
}

.btn:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
</style>